import React, { createContext, useState, useContext, useCallback } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import useDeviceHttpProxy from '../hooks/useDeviceHttpProxy'
import { LoggingContext } from './LoggingContext'
import { nanoid } from 'nanoid'
import { EMPTY_FUNCTION, RESULT, SETUP_APP_BRANDING } from '../config/constants'
import { DirectionContext } from '@veneer/core/dist/esm/scripts/direction'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

export const ConfigContext = createContext({
  authProvider: null,
  sessionContext: null,
  localization: null,
  stack: Stack.pie,
  getText: EMPTY_FUNCTION,
  getTextTree: EMPTY_FUNCTION,
  closeServiceInstance: EMPTY_FUNCTION,
  init: EMPTY_FUNCTION,
  isMobile: null,
  sessionId: null,
  shellLanguage: null,
  shellCountry: null,
  odLanguage: null,
  odCountry: null,
  launchDHPEmulator: null,
  sendDHPRequest: null,
  publishCdmEvent: EMPTY_FUNCTION,
  isRTL: false,
  direction: 'auto',
  publishRumEvent: EMPTY_FUNCTION
})

const DEFAULT_COUNTRY = 'us'
const DEFAULT_LANGUAGE = 'en'

const ConfigProvider = (props) => {
  const {
    authProvider = null,
    analytics = null,
    store = null,
    localization = {
      country: DEFAULT_COUNTRY,
      language: DEFAULT_LANGUAGE,
      enabled: false
    },
    stack = Stack.pie,
    serviceRouting = null,
    monitoring = null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t = (key, options) => key,
    closeServiceInstanceOutputKey = 'liveUiFeatures'
  } = props
  const { initializeDHP, launchDHPEmulator, sendDHPRequest } =
    useDeviceHttpProxy()
  const { uiBrand } = useMultiUiBrandContext()
  const [sessionId, setSessionId] = useState('')
  const country = (localization.country || DEFAULT_COUNTRY).toUpperCase()
  const language = localization.language || DEFAULT_LANGUAGE
  const publishCdmEvent = analytics?.publishCdmEvents || EMPTY_FUNCTION
  const sessionContext = store?.state?.onboarding?.sessionContext
  const isHPX = uiBrand === UiTheme.hpx
  const isOsTypeMobile = ['ios', 'android'].includes(
    sessionContext?.app?.osType
  )
  const isMobile =
    isOsTypeMobile &&
    (!isHPX || (window.innerHeight < 1024 && window.innerWidth < 1024))
  const { isRTL } = useContext(DirectionContext)
  const startSpan = monitoring?.startSpan || EMPTY_FUNCTION
  const { Logger } = useContext(LoggingContext)

  const closeServiceInstance = useCallback(
    async (result = RESULT.SUCCESS, output = null, errorInfo = null) => {
      const xCorrelationId = sessionContext?.xCorrelationId
      const closeServiceInstanceOptions = {
        resultData: { result: result, xCorrelationId: xCorrelationId }
      }
      if (output !== null) {
        closeServiceInstanceOptions.resultData['output'] = {}
        closeServiceInstanceOptions.resultData['output'][
          `${closeServiceInstanceOutputKey}`
        ] = output
      }
      if (errorInfo !== null) {
        closeServiceInstanceOptions.resultData['errorInfo'] = errorInfo
      }
      Logger.log('calling closeServiceInstance')
      Logger.log(closeServiceInstanceOptions)
      try {
        await serviceRouting.closeServiceInstance(closeServiceInstanceOptions)
      } catch (error) {
        Logger.error(`call to closeServiceInstance failed - ${error}`)
      }
    },
    [
      Logger,
      closeServiceInstanceOutputKey,
      serviceRouting,
      sessionContext?.xCorrelationId
    ]
  )

  const _getAppSessionId = useCallback(async () => {
    let id = `SIMID-${nanoid()}`
    try {
      const serviceLaunchOptions =
        await serviceRouting.getServiceInstanceLaunchOptions()
      Logger.log('calling getServiceInstanceLaunchOptions')
      Logger.log(serviceLaunchOptions)
      const { serviceOptions } = serviceLaunchOptions
      if (serviceOptions.appSessionId) {
        id = serviceOptions.appSessionId
      }
    } catch (e) {
      Logger.error(`call to getServiceInstanceLaunchOptions failed - ${e}`)
    }
    Logger.log(`appSessionId ${id}`)
    return id
  }, [Logger, serviceRouting])

  const _logDirectionInfos = useCallback(() => {
    Logger.log(`isRTL: ${isRTL} - country: ${country} - language: ${language}`)
  }, [Logger, country, isRTL, language])

  const init = useCallback(
    async (sessionId) => {
      await initializeDHP()
      const appSessionId = sessionId || (await _getAppSessionId())
      _logDirectionInfos()
      setSessionId(appSessionId)
    },
    [_getAppSessionId, _logDirectionInfos, initializeDHP]
  )

  const _extractOdLanguage = useCallback(() => {
    // This workaround is required until HPXAPPS-4591 is implemented - Onboarding Director is supposed to perform schema
    // validation on countryRegionIso and locale fields provided via app object. Current implementation allows for any
    // string to be passed in these fields, which can lead to issues when trying to extract the language from the locale.
    //
    // OD spec defines locale to be represented as {language-code}_{country-code}, per ISO 3166 and ISO 639, respectively.
    // There are current setup app logs showing locale in the wrong format, e.g. 'en-US' instead of 'en_US'.
    const locale = sessionContext?.app?.locale
    const charExtractor = locale?.indexOf('-') !== -1 ? '-' : '_'
    return locale?.split(charExtractor)[0] || null
  }, [sessionContext])

  const _replaceBranding = useCallback(
    (text) => {
      if (!isHPX) {
        return text
      }
      if (typeof text === 'string') {
        return text.replace(SETUP_APP_BRANDING.HP_SMART, SETUP_APP_BRANDING.HPX)
      }
      if (typeof text === 'object' && text) {
        Object.keys(text).forEach((k) => {
          text[k] = _replaceBranding(text[k])
        })
      }
      return text
    },
    [isHPX]
  )

  const configState = React.useMemo(
    () => ({
      authProvider,
      localization,
      stack,
      sessionContext,
      shellLanguage: language,
      shellCountry: country,
      odLanguage: _extractOdLanguage(),
      odCountry: sessionContext?.app?.countryRegionIso || null,
      getText: (key) => {
        return _replaceBranding(t(key))
      },
      getTextTree: (key) => {
        return _replaceBranding(t(key, { returnObjects: true }))
      },
      closeServiceInstance,
      init,
      publishCdmEvent,
      sessionId,
      launchDHPEmulator,
      sendDHPRequest,
      isMobile,
      isRTL,
      direction: isRTL ? 'rtl' : 'ltr',
      publishRumEvent: async (name, attributes = {}) => {
        const span = startSpan(name, attributes)
        await span?.end?.()
      }
    }),
    [
      authProvider,
      localization,
      stack,
      sessionContext,
      language,
      country,
      _extractOdLanguage,
      closeServiceInstance,
      init,
      publishCdmEvent,
      sessionId,
      launchDHPEmulator,
      sendDHPRequest,
      isMobile,
      isRTL,
      t,
      startSpan,
      _replaceBranding
    ]
  )

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}
export default ConfigProvider
