import React, { useContext } from 'react'
import classes from '../../pages/Completed.module.scss'
import { ConfigContext } from '../../store/ConfigContext'
import {
  UiTheme,
  useMultiUiBrandContext,
  ProgressIndicator
} from '@jarvis/react-setup-and-onboarding-addons'

export const FullscreenProgress = (props) => {
  const { text } = props
  const { direction } = useContext(ConfigContext)
  const { uiBrand } = useMultiUiBrandContext()
  const isHpx = uiBrand === UiTheme.hpx
  return (
    <>
      <div
        className={`${classes.completed} ${classes.spinner}`}
        dir={direction}
      >
        <div className={isHpx ? classes.progressBarHpx : classes.progressBar}>
          <ProgressIndicator
            data-testid="spinner"
            appearance="circular"
            behavior="indeterminate"
            size={isHpx ? 'large' : 'small'}
          />
          {text && (
            <label className={isHpx ? classes.labelHpx : classes.label}>
              {text}
            </label>
          )}
        </div>
      </div>
    </>
  )
}
