// CDM/LEDM data specific
export const STATUS_NOT_STARTED = 'notStarted'
export const STATUS_COMPLETED = 'completed'
export const STATUS_IN_PROGRESS = 'inProgress'
export const STATUS_PENDING = 'pending'
export const STATUS_FAILED = 'failed'
export const TRANSITION_TYPE_AUTO = 'auto'
export const TRANSITION_TYPE_MANUAL = 'manual'
export const SEVERITY_ERROR = 'severityError'
export const CARTRIDGE_MISSING_ERROR = 'cartridgeMissing'
export const CARTRIDGE_COUNTERFEIT_ERROR = 'cartridgeCounterfeitQuestion'
export const SINGLE_CARTRIDGE_MODE_ERROR = 'singleCartridgeMode'
export const SUPPLY_NOT_DETECTED_ERROR = 'SUPPLY_NOT_DETECTED_ERROR'
export const SUPPLY_ERROR = 'SUPPLY_ERROR'

// CDM data specific
export const CDM_PAYLOAD_USER_RESPONSE = 'userResponse'
export const STATUS_PROCESSING = 'processing'
export const STATUS_SKIPPED = 'skipped'
export const STATUS_INTERVENTION = 'intervention'
export const ACTION_LOAD_MAIN_TRAY = 'actionLoadMainTray'
export const ACTION_LANGUAGE_COUNTRY = 'actionLanguageCountry'
export const ACTION_INSTALL_CARTRIDGES = 'actionInstallCartridges'
export const ACTION_INSTALL_PHA = 'actionInstallPha'
export const ACTION_FILL_INK_TANKS = 'actionFillInkTanks'
export const ACTION_SEMI_AUTO_CALIBRATION = 'actionSemiAutoCalibration'
export const STATE = 'state'
export const CANCEL_PROCESSING = 'cancelProcessing'
export const PAPER_TRAY_PACKAGING = 'paperTrayPackaging'
export const CARRIAGE_RESTRAINT = 'carriageRestraint'
export const TIME_OUT_ERROR = 'TIME_OUT_ERROR'
export const TIME_OUT = 'timeOut'

// LEDM data specific
export const STATUS_GENERIC_FAILURE = 'genericFailure'
export const LANGUAGE_CONFIG = 'languageConfig'
export const COUNTRY_CONFIG = 'countryConfig'
export const LOAD_MAIN_TRAY = 'loadMainTray'
export const INSTALL_PHA = 'installPHA'
export const FILL_INK_TANKS = 'fillInkTanks'
export const REMOVE_PROTECTIVE_SHEET = 'removeProtectiveSheet'
export const SEMI_CALIBRATION_PRINT = 'semiCalibrationPrint'
export const SEMI_CALIBRATION_SCAN = 'semiCalibrationScan'
export const INSERT_INK = 'insertInk'
export const REMOVE_WRAP = 'removeWrap'
export const CALIBRATION = 'calibration'
export const PHOTO_TRAY = 'PhotoTray'
export const START_CALIBRATION_PAYLOAD =
  '<?xml version="1.0" encoding ="UTF-8" ?><CalibrationState xmlns="http://www.hp.com/schemas/imaging/con/cnx/markingagentcalibration/2009/04/08" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.hp.com/schemas/imaging/con/cnx/markingagentcalibration/2009/04/08 MarkingAgentCalibration_025.xsd">Printing</CalibrationState>'
export const SETUP_OOBE_STAGE_PAYLOAD =
  '<?xml version="1.0" encoding="UTF-8"?><ob:OOBEConfig xsi:schemaLocation ="http://www.hp.com/schemas/imaging/con/ledm/oobe/2015/12/14 ../OOBE/OOBE.xsd" xmlns:dd ="http://www.hp.com/schemas/imaging/con/dictionaries/1.0/" xmlns:ob="http://www.hp.com/schemas/imaging/con/ledm/oobe/2015/12/14" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><ob:SetupOOBEStage>$stage</ob:SetupOOBEStage></ob:OOBEConfig>'
export const SET_LOCALE_PAYLOAD =
  '<?xml version="1.0" encoding="UTF-8"?><prdcfgdyn2:ProductConfigDyn xmlns:prdcfgdyn="http://www.hp.com/schemas/imaging/con/ledm/productconfigdyn/2007/11/05" xmlns:prdcfgdyn2="http://www.hp.com/schemas/imaging/con/ledm/productconfigdyn/2009/03/16" xmlns:dd="http://www.hp.com/schemas/imaging/con/dictionaries/1.0/"><prdcfgdyn2:ProductSettings><prdcfgdyn:ProductLanguage><dd:DeviceLanguage>$language</dd:DeviceLanguage></prdcfgdyn:ProductLanguage><prdcfgdyn:CountryAndRegionName>$country</prdcfgdyn:CountryAndRegionName></prdcfgdyn2:ProductSettings></prdcfgdyn2:ProductConfigDyn>'

// LEDM Fill Ink Tanks specific
export const SCREW_CAP = 'screwCap'
export const FLIP_CAP = 'flipCap'

// CDM Calibration specific
export const ALIGNMENT_PAGE = 'alignmentPage'
export const PEN_ALIGNMENT_SEMI_AUTO = 'penAlignSemiauto'

// Live UI version
export const LIVE_UI_VERSION_2 = 2
export const LIVE_UI_VERSION_1 = 1

// Button visibility types
export const DISABLED = 2
export const VISIBLE = 1
export const HIDDEN = 0

// Delay/timeout types
export const DELAY_3_SEC = 3 * 1000
export const DELAY_15_SEC = 5 * DELAY_3_SEC
export const DELAY_30_SEC = 10 * DELAY_3_SEC
export const DELAY_60_SEC = 2 * DELAY_30_SEC
export const DELAY_3_MIN = 3 * DELAY_60_SEC

export const EMPTY_FUNCTION = () => {
  // Intentional empty function to avoid sonarqube code smells
}

// Onboarding Director specific
export const SERVICE_ID = 'owsc-jwv-liveui'
export const DEVICE_NOT_SUPPORTED = 'notSupported'
export const DEVICE_CDM_ONLY = 'cdmOnly'
export const DEVICE_LEDM_ONLY = 'ledmOnly'
export const DEVICE_LEDM_AND_CDM = 'ledmAndCdm'

export const RESULT = {
  FAILURE: 'failure',
  DECLINED: 'declined',
  SUCCESS: 'success',
  INELIGIBLE: 'ineligible',
  ALREADY_PERFORMED: 'alreadyPerformed'
}

// DeviceHttpProxy cache interface
export const CACHE_STRATEGY_CACHEFIRST = 'cacheFirst'
export const MAX_AGE_IN_SECONDS = 1800

// DeviceHttpProxy error code mapping
export const DEFAULT_ERROR_CODE = 'OW_DHP00001'
export const DHP_NON_200_ERROR_CODE = 'OW_DHP00005'

export const DHP_REQUEST_REJECTED_ERROR = 'requestRejected'
export const DHP_DISCOVERY_TREE_EMPTY_ERROR = 'discoveryTreeEmpty'
export const DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR = 'oobeManifestEmpty'
export const DHP_DEVICE_STATUS_EMPTY_ERROR = 'deviceStatusEmpty'

export const DHP_ERROR_CODE = {
  invalidArgument: 'OW_DHP00002',
  dataValveError: 'OW_DHP00003',
  severityError: 'OW_PAL00001', // non-DHP error type
  invalidSession: 'HS_DHP00001',
  sessionNotActive: 'HS_DHP00002',
  deviceOffline: 'HS_DHP00003',
  requestTimedOut: 'FW_DHP00001',
  cancelled: 'FW_DHP00002',
  unknownError: 'FW_DHP00003',
  contentStackEmptyError: 'OW_CS00001', // non-DHP error type
  contentStackLocaleError: 'OW_CS00002', // non-DHP error type
  contentStackCatchAllError: 'OW_CS00003', // non-DHP error type
  requestRejected: 'HS_DHP00004', // non-DHP error type
  discoveryTreeEmpty: 'FW_DHP00004', // non-DHP error type
  oobeManifestEmpty: 'FW_DHP00005', // non-DHP error type
  unableToDecode: 'OW_DHP00004', // non-DHP error type
  deviceStatusEmpty: 'FW_DHP00006' // non-DHP error type
}

// DeviceHttpProxy fallback resource URIs
export const RESOURCE_URI_FALLBACK = {
  CDM: {
    calibrationAlerts: '/cdm/alert/v1/alerts',
    calibrationCapabilities:
      '/cdm/calibration/v1/calibrations/penAlignSemiauto',
    cartridgeAlerts: '/cdm/alert/v1/alerts',
    cartridgeSetup: '/cdm/supply/v1/cartridgeSetup',
    deviceSetup: '/cdm/deviceSetup/v1/status',
    packageInformation: '/cdm/manufacturing/v1/packageInformation',
    reportsPrint: '/cdm/report/v1/print',
    suppliesPublic: '/cdm/supply/v1/suppliesPublic',
    systemConfig: '/cdm/system/v1/configuration',
    systemConstraints: '/cdm/system/v1/configuration/constraints',
    systemIdentity: '/cdm/system/v1/identity'
  },
  LEDM: {
    calibrationManifest: '/Calibration/CalibrationManifest.xml',
    calibrationSession: '/Calibration/Session',
    calibrationState: '/Calibration/State',
    oobeConfig: '/OOBE/Config',
    oobeDeviceInfo: '/OOBE/DeviceInfo',
    oobeManifest: '/OOBE/OOBEManifest.xml',
    oobeStatus: '/OOBE/Status',
    productConfigDyn: '/DevMgmt/ProductConfigDyn.xml',
    productConfigCap: '/DevMgmt/ProductConfigCap.xml',
    productStatusDyn: '/DevMgmt/ProductStatusDyn.xml'
  }
}

// Analytics
const BASE_PATH = '/LiveUi/'
const BASE_PATH_REMOVE_CARDBOARD = '/RemoveCardboard/'
const BASE_PATH_LANGUAGE_COUNTRY = '/LanguageCountry/'

const ACTIVITY = 'HardwareSetup-v01'

const ERROR_CODE = 'ErrorCode'

const MODAL = {
  SUCCESS: 'SuccessModal',
  ALERT: 'AlertModal',
  WARNING: 'WarningModal',
  SPINNER: 'SpinnerModal'
}
const ALERT_TRAY = 'AlertTray'

export const ANALYTICS = {
  ACTIVITY,
  BASE_PATH,
  BASE_PATH_REMOVE_CARDBOARD,
  ERROR_CODE,
  SCREENS: {
    START: {
      screenName: 'Start',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    COMPLETED: {
      screenName: 'Completed',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    LANGUAGE_COUNTRY: {
      screenName: 'LanguageCountry',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    LOAD_PAPER: {
      screenName: 'LoadPaper',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    INSTALL_INK: {
      screenName: 'InstallInk',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    INSTALL_INK_MODAL_SPINNER: {
      screenName: MODAL.SPINNER,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallInk/`
    },
    FILL_INK_TANKS: {
      screenName: 'FillInkTanks',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    PREPARE_INK_TANKS: {
      screenName: 'PrepareInkTanks',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}FillInkTanks/`
    },
    SELECT_INK_BOTTLE: {
      screenName: 'SelectInkBottle',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}FillInkTanks/`
    },
    INSTALL_PRINT_HEADS: {
      screenName: 'InstallPrintHeads',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    PRINT_ALIGNMENT: {
      screenName: 'PrintAlignment',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    SCAN_ALIGNMENT: {
      screenName: 'ScanAlignment',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    AUTO_ALIGNMENT: {
      screenName: 'AutoAlignment',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    AUTO_ALIGNMENT_FULLSCREEN_ERROR: {
      screenName: 'FullScreenError',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}AutoAligment/`
    },
    GENERIC_ERROR: {
      screenName: 'GenericError',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    REMOVE_PROTECTIVE_SHEET: {
      screenName: 'RemoveProtectiveSheet',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    REMOVE_WRAP: {
      screenName: 'RemoveWrap',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    LANGUAGE_COUNTRY_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}LanguageCountry/`
    },
    LOAD_PAPER_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}LoadPaper/`
    },
    INSTALL_INK_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallInk/`
    },
    FILL_INK_TANKS_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}FillInkTanks/`
    },
    INSTALL_PRINT_HEADS_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallPrintHeads/`
    },
    REMOVE_WRAP_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}RemoveWrap/`
    },
    SCAN_ALIGNMENT_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}ScanAlignment/`
    },
    INSTALL_INK_MODAL_ERROR: {
      screenName: MODAL.ALERT,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallInk/`
    },
    INSTALL_INK_TRAY_ERROR: {
      screenName: ALERT_TRAY,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallInk/`
    },
    FILL_INK_TANKS_MODAL_ERROR: {
      screenName: MODAL.ALERT,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}FillInkTanks/`
    },
    FILL_INK_TANKS_TRAY_ERROR: {
      screenName: ALERT_TRAY,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}FillInkTanks/`
    },
    INSTALL_PRINT_HEADS_MODAL_ERROR: {
      screenName: MODAL.ALERT,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallPrintHeads/`
    },
    INSTALL_PRINT_HEADS_TRAY_ERROR: {
      screenName: ALERT_TRAY,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}InstallPrintHeads/`
    },
    PRINT_ALIGNMENT_FULLSCREEN_ERROR: {
      screenName: 'FullScreenError',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}PrintAlignment/`
    },
    PRINT_ALIGNMENT_MODAL_WARNING: {
      screenName: MODAL.WARNING,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}PrintAlignment/`
    },
    PRINT_ALIGNMENT_MODAL_SPINNER: {
      screenName: MODAL.SPINNER,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}PrintAlignment/`
    },
    SCAN_ALIGNMENT_MODAL_WARNING: {
      screenName: MODAL.WARNING,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}ScanAlignment/`
    },
    SCAN_ALIGNMENT_MODAL_SPINNER: {
      screenName: MODAL.SPINNER,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}ScanAlignment/`
    },
    AUTO_ALIGNMENT_MODAL_SPINNER: {
      screenName: MODAL.SPINNER,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}AutoAlignment/`
    },
    AUTO_ALIGNMENT_MODAL_SUCCESS: {
      screenName: MODAL.SUCCESS,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}AutoAlignment/`
    },
    AUTO_ALIGNMENT_MODAL_WARNING: {
      screenName: MODAL.WARNING,
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}AutoAlignment/`
    },
    START_REMOVE_CARDBOARD: {
      screenName: 'Start',
      activity: ACTIVITY,
      screenPath: BASE_PATH_REMOVE_CARDBOARD
    },
    COMPLETED_REMOVE_CARDBOARD: {
      screenName: 'Completed',
      activity: ACTIVITY,
      screenPath: BASE_PATH_REMOVE_CARDBOARD
    },
    REMOVE_CARDBOARD: {
      screenName: 'RemoveCardboard',
      activity: ACTIVITY,
      screenPath: BASE_PATH_REMOVE_CARDBOARD
    },
    REMOVE_CARDBOARD_MODAL_SPINNER: {
      screenName: 'SpinnerModal',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH_REMOVE_CARDBOARD}RemoveCardboard/`
    },
    REMOVE_CARDBOARD_MODAL_SUCCESS: {
      screenName: 'SuccessModal',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH_REMOVE_CARDBOARD}RemoveCardboard/`
    },
    REMOVE_CARDBOARD_MODAL_ERROR: {
      screenName: 'AlertModal',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH_REMOVE_CARDBOARD}RemoveCardboard/`
    },
    REMOVE_CARDBOARD_TRAY_ERROR: {
      screenName: 'AlertTray',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH_REMOVE_CARDBOARD}RemoveCardboard/`
    },
    SCALABLE_ERROR: {
      screenName: 'ScalableError',
      activity: ACTIVITY,
      screenPath: BASE_PATH_REMOVE_CARDBOARD
    },
    SCALABLE_ERROR_MODAL_SUCCESS: {
      screenName: 'SuccessModal',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH_REMOVE_CARDBOARD}ScalableError/`
    },
    GENERIC_ERROR_REMOVE_CARDBOARD: {
      screenName: 'GenericError',
      activity: ACTIVITY,
      screenPath: BASE_PATH_REMOVE_CARDBOARD
    },
    PAGE_DID_NOT_PRINT: {
      screenName: 'PageDidNotPrint',
      activity: ACTIVITY,
      screenPath: `${BASE_PATH}PrintAlignment/`
    },
    SELECTOR_LANGUAGE_COUNTRY: {
      screenName: 'LanguageCountry',
      activity: ACTIVITY,
      screenPath: BASE_PATH_LANGUAGE_COUNTRY
    },
    COMPLETED_LANGUAGE_COUNTRY: {
      screenName: 'Completed',
      activity: ACTIVITY,
      screenPath: BASE_PATH_LANGUAGE_COUNTRY
    },
    START_LANGUAGE_COUNTRY: {
      screenName: 'Start',
      activity: ACTIVITY,
      screenPath: BASE_PATH_LANGUAGE_COUNTRY
    },
    GENERIC_ERROR_LANGUAGE_COUNTRY: {
      screenName: 'GenericError',
      activity: ACTIVITY,
      screenPath: BASE_PATH_LANGUAGE_COUNTRY
    }
  },
  CONTROLS: {
    CONTINUE: 'ContinueButton',
    SKIP: 'SkipButton',
    EXIT_SETUP: 'ExitSetupButton',
    COLLAPSE: 'CollapseButton',
    EXPAND: 'ExpandButton',
    RETRY: 'RetryButton',
    PAGE_DID_NOT_PRINT: 'PageDidNotPrint',
    LANGUAGE_COUNTRY_SPINNER: 'LanguageCountrySpinner',
    LANGUAGE_COUNTRY_SELECTOR: 'LanguageCountrySelector',
    SILENT_PATCH: 'SilentPatch',
    HELP_INFO: 'HelpInfoLink'
  },
  MODES: {
    UI: {
      [true]: 'Active',
      [false]: 'Passive'
    }
  },
  SUPPORT_LINK: 'SupportLink'
}

// Animations
export const ANIMATION_NAME_PREFIX = 'animation_'

// Supported CDM actions
export const SUPPORTED_CDM_ACTIONS = [
  ACTION_LANGUAGE_COUNTRY,
  ACTION_LOAD_MAIN_TRAY,
  ACTION_FILL_INK_TANKS,
  ACTION_INSTALL_PHA,
  ACTION_INSTALL_CARTRIDGES,
  ACTION_SEMI_AUTO_CALIBRATION
]

// Supported LEDM actions
export const SUPPORTED_LEDM_ACTIONS = [
  LANGUAGE_CONFIG,
  COUNTRY_CONFIG,
  LOAD_MAIN_TRAY,
  REMOVE_PROTECTIVE_SHEET,
  REMOVE_WRAP,
  INSTALL_PHA,
  SEMI_CALIBRATION_PRINT,
  SEMI_CALIBRATION_SCAN,
  FILL_INK_TANKS,
  INSERT_INK,
  CALIBRATION
]

// Language Country page specific
export const languageDisplayName = {
  af: 'Afrikaans',
  ar: 'العربية',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'български',
  bn: 'Bengali',
  br: 'Breton',
  bs: 'Bosnian',
  ca: 'Català',
  cs: 'Čeština',
  cy: 'Welsh',
  da: 'Dansk',
  de: 'Deutsch',
  el: 'ελληνικά',
  en: 'English',
  es: 'Español',
  et: 'Estonian',
  eu: 'Basque (East European)',
  fa: 'Persian',
  fi: 'Suomi',
  fr: 'Français',
  ga: 'Irish',
  gd: 'Gaelic; Scottish Gaelic',
  he: 'עִברִית',
  hi: 'Hindi',
  hr: 'Hrvatski',
  hu: 'Magyar',
  hy: 'Armenian',
  id: 'Bahasa Indonesia',
  is: 'Icelandic',
  it: 'Italiano',
  ja: '日本語',
  ka: 'Georgian',
  ko: '한국어',
  lt: 'Lithuanian',
  lv: 'Latvian',
  ms: 'Malay',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ru: 'Pусский',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sv: 'Svenska',
  th: 'ไทย',
  tr: 'Türkçe',
  uk: 'Українська',
  vi: 'Vietnamese',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文'
}

export const countryIsoDisplayName = {
  AD: 'Andorra',
  AE: 'الإمارات العربية',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Österreich',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'België',
  BF: 'Burkina Faso',
  BG: 'България',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BR: 'Brasil',
  BS: 'The Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Беларусь',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos Keeling Islands',
  CD: 'République démocratique du Congo',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Schweizerische',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: '中国',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CS: 'Serbia and Montenegro',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CY: 'Cyprus',
  CZ: 'Česká republika',
  DE: 'Deutschland',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Danmark',
  DM: 'Dominica',
  DO: 'República Dominicana',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'مصر',
  ER: 'Eritrea',
  ES: 'España',
  ET: 'Ethiopia',
  FI: 'Suomi',
  FJ: 'Fiji Islands',
  FK: 'Falkland Islands Islas Malvinas',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'The Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Guinea Ecuatorial',
  GR: 'Ελλάς',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea Bissau',
  GY: 'Guyana',
  HK: '香港特別行政區',
  HN: 'Honduras',
  HR: 'Hrvatska',
  HT: 'Haiti',
  HU: 'Magyarország',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'יִשְׂרָאֵל',
  IN: 'India',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italia',
  JM: 'Jamaica',
  JO: 'الأردن',
  JP: '日本',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'St Kitts and Nevis',
  KP: 'North Korea',
  KR: '한국',
  KW: 'كويت',
  KY: 'Cayman Islands',
  KZ: 'Казахстан',
  LA: 'Laos',
  LB: 'لبنان',
  LC: 'St Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'المغرب',
  MC: 'Monaco',
  MD: 'Moldova',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'México',
  MY: 'Malaysia',
  MZ: 'Moçambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Nederland',
  NO: 'Norge',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panamá',
  PE: 'Perú',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Polska',
  PM: 'St Pierre and Miquelon',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'قطر',
  RE: 'Reunion',
  RO: 'România',
  RU: 'Росси́я',
  RW: 'Rwanda',
  SA: 'المملكة العربية السعودية',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sverige',
  SG: 'Singapore',
  SH: 'St Helena',
  SI: 'Slovenija',
  SK: 'Slovensko',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SV: 'El Salvador',
  SY: 'Syria',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'ไทย',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor Leste',
  TM: 'Turkmenistan',
  TN: 'تونس',
  TO: 'Tonga',
  TR: 'Türkiye',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: '臺灣地區',
  TZ: 'Tanzania',
  UA: 'Україна',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'St Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands ',
  VI: 'Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'اليمن',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
}

export const countryDisplayName = {
  afghanistan: 'Afghanistan',
  albania: 'Albania',
  algeria: 'Algeria',
  americanSamoa: 'American Samoa',
  andorra: 'Andorra',
  angola: 'Angola',
  anguilla: 'Anguilla',
  antiguaAndBarbuda: 'Antigua and Barbuda',
  argentina: 'Argentina',
  armenia: 'Armenia',
  aruba: 'Aruba',
  ascensionIsland: 'Ascension Island',
  australia: 'Australia',
  austria: 'Österreich',
  azerbaijan: 'Azerbaijan',
  bahamasThe: 'The Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belarus: 'Беларусь',
  belgium: 'België',
  belize: 'Belize',
  benin: 'Benin',
  bermuda: 'Bermuda',
  bhutan: 'Bhutan',
  bolivia: 'Bolivia',
  bosniaAndHerzegovina: 'Bosnia and Herzegovina',
  botswana: 'Botswana',
  brazil: 'Brasil',
  brunei: 'Brunei',
  bulgaria: 'България',
  burkinaFaso: 'Burkina Faso',
  burundi: 'Burundi',
  cambodia: 'Cambodia',
  cameroon: 'Cameroon',
  canada: 'Canada',
  capeVerde: 'Cape Verde',
  caymanIslands: 'Cayman Islands',
  centralAfricanRepublic: 'Central African Republic',
  chad: 'Chad',
  chile: 'Chile',
  china: '中国',
  cocosKeelingIslands: 'Cocos Keeling Islands',
  colombia: 'Colombia',
  comoros: 'Comoros',
  congo: 'Congo',
  congoDRC: 'République démocratique du Congo',
  cookIslands: 'Cook Islands',
  costaRica: 'Costa Rica',
  croatia: 'Hrvatska',
  cuba: 'Cuba',
  cyprus: 'Cyprus',
  czechRepublic: 'Česká republika',
  denmark: 'Danmark',
  diegoGarcia: 'Diego Garcia',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  dominicanRepublic: 'República Dominicana',
  ecuador: 'Ecuador',
  egypt: 'مصر',
  elSalvador: 'El Salvador',
  equatorialGuinea: 'Guinea Ecuatorial',
  eritrea: 'Eritrea',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  falklandIslandsIslasMalvinas: 'Falkland Islands Islas Malvinas',
  faroeIslands: 'Faroe Islands',
  fijiIslands: 'Fiji Islands',
  finland: 'Suomi',
  france: 'France',
  frenchAntilles: 'French Antilles',
  frenchGuiana: 'French Guiana',
  frenchPolynesia: 'French Polynesia',
  gabon: 'Gabon',
  gambiaThe: 'The Gambia',
  georgia: 'Georgia',
  germany: 'Deutschland',
  ghana: 'Ghana',
  gibraltar: 'Gibraltar',
  greece: 'Ελλάς',
  greenland: 'Greenland',
  grenada: 'Grenada',
  guadeloupe: 'Guadeloupe',
  guam: 'Guam',
  guantanamoBay: 'Guantanamo Bay',
  guatemala: 'Guatemala',
  guinea: 'Guinea',
  guineaBissau: 'Guinea Bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hongKongSAR: '香港特別行政區',
  hungary: 'Magyarország',
  iceland: 'Iceland',
  india: 'India',
  indonesia: 'Indonesia',
  iran: 'Iran',
  iraq: 'Iraq',
  ireland: 'Ireland',
  israel: 'יִשְׂרָאֵל',
  italy: 'Italia',
  jamaica: 'Jamaica',
  japan: '日本',
  jordan: 'الأردن',
  kazakhstan: 'Казахстан',
  kenya: 'Kenya',
  kiribati: 'Kiribati',
  korea: '한국',
  kuwait: 'كويت',
  kyrgyzstan: 'Kyrgyzstan',
  laos: 'Laos',
  latvia: 'Latvia',
  lebanon: 'لبنان',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libya: 'Libya',
  liechtenstein: 'Liechtenstein',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  lacaoSAR: 'Lacao SAR',
  macedonia: 'Macedonia',
  madagascar: 'Madagascar',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  maldives: 'Maldives',
  mali: 'Mali',
  malta: 'Malta',
  marshallIslands: 'Marshall Islands',
  martinique: 'Martinique',
  mauritania: 'Mauritania',
  mauritius: 'Mauritius',
  mayotte: 'Mayotte',
  mexico: 'México',
  micronesia: 'Micronesia',
  moldova: 'Moldova',
  monaco: 'Monaco',
  mongolia: 'Mongolia',
  montserrat: 'Montserrat',
  morocco: 'المغرب',
  mozambique: 'Moçambique',
  myanmar: 'Myanmar',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlandsAntilles: 'Netherlands Antilles',
  netherlandsThe: 'Nederland',
  newCaledonia: 'New Caledonia',
  newZealand: 'New Zealand',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  niue: 'Niue',
  norfolkIsland: 'Norfolk Island',
  northKorea: 'North Korea',
  norway: 'Norge',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  panama: 'Panamá',
  papuaNewGuinea: 'Papua New Guinea',
  paraguay: 'Paraguay',
  peru: 'Perú',
  philippines: 'Philippines',
  poland: 'Polska',
  portugal: 'Portugal',
  puertoRico: 'Puerto Rico',
  qatar: 'قطر',
  reunion: 'Reunion',
  romania: 'România',
  russia: 'Росси́я',
  rwanda: 'Rwanda',
  samoa: 'Samoa',
  sanMarino: 'San Marino',
  saudiArabia: 'المملكة العربية السعودية',
  senegal: 'Senegal',
  serbiaAndMontenegro: 'Serbia and Montenegro',
  seychelles: 'Seychelles',
  sierraLeone: 'Sierra Leone',
  singapore: 'Singapore',
  slovakia: 'Slovensko',
  slovenia: 'Slovenija',
  solomonIslands: 'Solomon Islands',
  somalia: 'Somalia',
  southAfrica: 'South Africa',
  spain: 'España',
  sriLanka: 'Sri Lanka',
  stHelena: 'St Helena',
  stKittsAndNevis: 'St Kitts and Nevis',
  stLucia: 'St Lucia',
  stPierreAndMiquelon: 'St Pierre and Miquelon',
  stVincentAndTheGrenadines: 'St Vincent and the Grenadines',
  sudan: 'Sudan',
  suriname: 'Suriname',
  swaziland: 'Swaziland',
  sweden: 'Sverige',
  switzerland: 'Schweizerische',
  syria: 'Syria',
  taiwan: '臺灣地區',
  tajikistan: 'Tajikistan',
  tanzania: 'Tanzania',
  thailand: 'ไทย',
  timorLeste: 'Timor Leste',
  togo: 'Togo',
  tokelau: 'Tokelau',
  tonga: 'Tonga',
  trinidadAndTobago: 'Trinidad and Tobago',
  tunisia: 'تونس',
  turkey: 'Türkiye',
  turkmenistan: 'Turkmenistan',
  turksAndCaicosIslands: 'Turks and Caicos Islands',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Україна',
  unitedArabEmirates: 'الإمارات العربية',
  unitedKingdom: 'United Kingdom',
  unitedStates: 'United States',
  uruguay: 'Uruguay',
  uzbekistan: 'Uzbekistan',
  vanuatu: 'Vanuatu',
  vaticanCity: 'Vatican City',
  venezuela: 'Venezuela',
  vietnam: 'Vietnam',
  virginIslands: 'Virgin Islands',
  virginIslandsBritish: 'British Virgin Islands ',
  wallisAndFutuna: 'Wallis and Futuna',
  yemen: 'اليمن',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe'
}

// Content Stack
export const CONTENT_STACK_EMPTY_ERROR = 'contentStackEmptyError'
export const CONTENT_STACK_LOCALE_ERROR = 'contentStackLocaleError'
export const CONTENT_STACK_CATCH_ALL_ERROR = 'contentStackCatchAllError'

export const ContentStackStacks = {
  api_key: 'blt59b6722a3f241109',
  preview_management_token: 'cs3615fbeb0dd6fb6195144d6d',
  pie: {
    delivery_token: 'cs47e91bdf5d2a81793f2fa038',
    env_name: 'pie'
  },
  stage: {
    delivery_token: 'cs335df8ff05e826f8181be88c',
    env_name: 'stage'
  },
  prod: {
    delivery_token: 'cs35594ca1f26ad3c1542e75f2',
    env_name: 'prod'
  }
}

export const ContentStackTypes = {
  carousel_instruction: {
    content_type_uid: 'instruction_carousel'
  },
  carousel_instruction_multi_header: {
    content_type_uid: 'instruction_carousel_multi_header'
  },
  alignment_page_did_not_print: {
    content_type_uid: 'alignment_page_did_not_print'
  }
}

export const ContentStackCarouselStages = {
  load_paper: 'load-paper',
  install_ink: 'install-ink',
  prepare_ink_tanks: 'prepare-ink-tanks',
  fill_ink_tanks: 'fill-ink-tanks',
  install_pha: 'install-pha',
  semi_calibration_print: 'semi-calibration-print',
  semi_calibration_scan: 'semi-calibration-scan',
  auto_calibration: 'auto-calibration',
  remove_protective_sheet: 'remove-protective-sheet',
  remove_wrap: 'remove-wrap',
  language_country: 'language-country',
  remove_cardboard: 'remove-cardboard'
}

// CDM/LEDM known alerts
export const _KNOW_PHOTO_TRAY_ALERTS = {
  65921: 'trayEmptyOrOpen',
  65583: 'trayEmptyOrOpen',
  65582: 'trayEmptyOrOpen',
  65564: 'trayEmptyOrOpen'
}

// KNOWN ALERTS - IO REFS
export const _KNOW_CALIBRATION_ALERTS = {
  ..._KNOW_PHOTO_TRAY_ALERTS,
  65769: 'cartridgeMissing',
  65537: 'cartridgeMissing',
  65827: 'cartridgeMissing',
  65589: 'cartridgeMissing',
  65690: 'cartridgeMissing',
  66262: 'cartridgeMissing',
  66207: 'cartridgeMissingPrintheads',
  65543: 'incompatibleConsumable',
  65830: 'incompatibleConsumable',
  65939: 'incompatibleConsumable',
  65676: 'incompatibleConsumableII',
  66213: 'incompatibleConsumablePrintheads',
  65773: 'antiTheftEnabledSupplyDetected',
  65843: 'antiTheftEnabledSupplyDetected',
  65612: 'antiTheftEnabledSupplyDetectedCartridges',
  65694: 'antiTheftEnabledSupplyDetectedCartridges',
  65585: 'jamInPrinter',
  65584: 'jamInPrinter',
  65563: 'jamInPrinter',
  65703: 'jamInPrinter',
  65942: 'jamInPrinter',
  65987: 'jamInPrinter',
  65797: 'trayOpen',
  65566: 'carriageJam',
  65579: 'carriageJam',
  65578: 'carriageJam',
  65568: 'closeDoorOrCover',
  65727: 'closeDoorOrCover',
  65541: 'printerError',
  66233: 'printerErrorPairing',
  65591: 'insertNonSETUPCartridge',
  65688: 'insertNonSETUPCartridgeII',
  66074: 'outputBinClosed',
  66339: 'dynamicSecurityCheckFailedChip',
  66341: 'dynamicSecurityCheckFailedCircuitry',
  66345: 'dynamicSecurityCheckFailedCircuitry',
  66271: 'antiTheftEnabledSupplyDetectedCartridgesHP+',
  66230: 'antiTheftEnabledSupplyDetectedPrintheads',
  66317: 'badManufacturingBatchCartridge',
  66329: 'badManufacturingBatchCartridge',
  66323: 'badManufacturingBatchCartridgeDoNotRemovePrintheads',
  66327: 'badManufacturingBatchCartridgeInUse',
  66328: 'badManufacturingBatchCartridgeInUseDoNotRemoveCartridge',
  66316: 'badManufacturingBatchCartridgeInUseDoNotRemoveCartridge',
  66322: 'badManufacturingBatchCartridgeInUseDoNotRemovePrintheads',
  66330: 'badManufacturingBatchCartridgeRemove',
  66331: 'badManufacturingBatchCartridgeRemove',
  65946: 'carriageJam',
  66012: 'carriageJamPrinthead',
  66162: 'carriageJamTube',
  66268: 'cartridgeCounterfeit',
  66267: 'cartridgeCounterfeit',
  66211: 'cartridgeFailurePrintheads',
  66215: 'cartridgeInWrongOrderPrintheads',
  65557: 'cartridgeLow',
  66242: 'cartridgeLowHP+',
  66017: 'cartridgeLowTanks',
  65546: 'cartridgeVeryLow',
  65776: 'cartridgeVeryLow',
  66241: 'cartridgeVeryLowHP+',
  66018: 'cartridgeVeryLowTanks',
  66332: 'cartridgeVeryLowTanks',
  65587: 'outputBinClosedOpenTray',
  66272: 'sharedAddressError',
  66209: 'sharedAddressErrorPrintheadFailure',
  66263: 'singleCartridgeModeBlocking',
  66235: 'subscribedPagesOut',
  66174: 'subscribedPagesOutNotConnected',
  66336: 'subscribedPagesOutNotConnected',
  65779: 'subscribedPagesOutReconnect',
  65741: 'subscribedPagesOutReconnectOrReplace',
  65742: 'subscribedPagesOutUpdatePayment',
  65768: 'subscriptionCancellationSuccessful',
  65850: 'subscriptionCancellationSuccessfulXMO',
  65598: 'supplyForwardIncompatible',
  66270: 'supplyForwardIncompatible',
  65689: 'supplyForwardIncompatibleII',
  66228: 'supplyForwardIncompatiblePrintheads',
  65846: 'trialConsumableCountExceeded',
  65629: 'inputTrayFailedToDisengage',
  65628: 'inputTrayFailedToDisengage',
  65702: 'mediaTooLongToAutoDuplex',
  65572: 'mediaTooShortToAutoDuplex',
  65570: 'paperSizeMismatch',
  65701: 'paperSizeMismatch',
  65936: 'paperSizeMismatch',
  65573: 'paperSizeMismatchWarning',
  65809: 'paperSizeMismatchWarning',
  65810: 'paperSizeMismatchWarning',
  65716: 'paperSizeMismatchWarning',
  65717: 'paperSizeMismatchWarning',
  65798: 'trayClosed',
  66090: 'trayEmptyLoadPaper',
  65803: 'trayEmptyOrOpenLoadPaper',
  65922: 'improperOrientationInTray',
  65788: 'mediaTooLongToAutoDuplex',
  65849: 'consumableSubscriptionOptionNotSet',
  66168: 'printerNeedsWSRegistration',
  66169: 'printerNeedsWSRegistration',
  66234: 'waitingForSupplyAssessment',
  65799: 'serviceStationStall'
}

export const _DISMISSIBLE_ALERTS = {
  65592: 'cartridgeCounterfeitQuestion',
  65685: 'cartridgeCounterfeitQuestion',
  65838: 'cartridgeCounterfeitQuestion',
  65617: 'cartridgeCounterfeitQuestion',
  65686: 'cartridgeCounterfeitQuestion',
  65594: 'cartridgeCounterfeitQuestion',
  65687: 'cartridgeCounterfeitQuestion',
  66098: 'cartridgeCounterfeitQuestion',
  66099: 'cartridgeCounterfeitQuestion',
  66266: 'cartridgeCounterfeitQuestion',
  66269: 'cartridgeCounterfeitQuestion',
  65590: 'insertSETUPCartridge',
  65765: 'subscriptionConsumableNeedsEnrollment'
}

export const _IGNORABLE_CALIBRATION_ALERTS = {
  65586: true, // calibrating
  65571: true, // calibrating
  ...Object.keys(_DISMISSIBLE_ALERTS).reduce((acc, key) => {
    acc[key] = true
    return acc
  }, {})
}

export const _HIGH_PRIORITY_ORDERED_INK_ALERTS = {
  65566: 'carriageJam',
  65578: 'carriageJam',
  65579: 'carriageJam',
  65946: 'carriageJam',
  65628: 'inputTrayFailedToDisengage',
  65629: 'inputTrayFailedToDisengage',
  65585: 'jamInPrinter',
  65584: 'jamInPrinter',
  65563: 'jamInPrinter',
  65703: 'jamInPrinter',
  65942: 'jamInPrinter',
  65987: 'jamInPrinter'
}

export const _KNOW_CARTRIDGE_ALERTS = {
  ..._HIGH_PRIORITY_ORDERED_INK_ALERTS,
  65769: 'cartridgeMissing',
  65537: 'cartridgeMissing',
  65827: 'cartridgeMissing',
  65589: 'cartridgeMissing',
  65690: 'cartridgeMissing',
  66262: 'cartridgeMissing',
  66207: 'cartridgeMissingPrintheads',
  65538: 'sharedAddressError',
  65770: 'sharedAddressErrorII',
  65828: 'cartridgeFailure',
  65542: 'cartridgeFailure',
  65675: 'cartridgeFailure',
  66211: 'cartridgeFailurePrintheads',
  65544: 'cartridgeInWrongOrder',
  66215: 'cartridgeInWrongOrderPrintheads',
  65543: 'incompatibleConsumable',
  65830: 'incompatibleConsumable',
  65939: 'incompatibleConsumable',
  65676: 'incompatibleConsumableII',
  66213: 'incompatibleConsumablePrintheads',
  65773: 'antiTheftEnabledSupplyDetected',
  65843: 'antiTheftEnabledSupplyDetected',
  65612: 'antiTheftEnabledSupplyDetectedCartridges',
  65694: 'antiTheftEnabledSupplyDetectedCartridges',
  65541: 'printerError',
  66233: 'printerErrorPairing',
  65568: 'closeDoorOrCover',
  65727: 'closeDoorOrCover',
  65771: 'singleCartridgeMode',
  65832: 'singleCartridgeMode',
  66263: 'singleCartridgeModeBlocking',
  65553: 'singleCartridgeModeEnabled',
  65591: 'insertNonSETUPCartridge',
  65688: 'insertNonSETUPCartridgeII',
  65590: 'insertSETUPCartridge',
  66074: 'outputBinClosed',
  66234: 'waitingForSupplyAssessment',
  66175: 'subscribedPagesOut',
  66339: 'dynamicSecurityCheckFailedChip',
  66341: 'dynamicSecurityCheckFailedCircuitry',
  66345: 'dynamicSecurityCheckFailedCircuitry',
  66271: 'antiTheftEnabledSupplyDetectedCartridgesHP+',
  66230: 'antiTheftEnabledSupplyDetectedPrintheads',
  66317: 'badManufacturingBatchCartridge',
  66329: 'badManufacturingBatchCartridge',
  66323: 'badManufacturingBatchCartridgeDoNotRemovePrintheads',
  66327: 'badManufacturingBatchCartridgeInUse',
  66328: 'badManufacturingBatchCartridgeInUseDoNotRemoveCartridge',
  66316: 'badManufacturingBatchCartridgeInUseDoNotRemoveCartridge',
  66322: 'badManufacturingBatchCartridgeInUseDoNotRemovePrintheads',
  66330: 'badManufacturingBatchCartridgeRemove',
  66331: 'badManufacturingBatchCartridgeRemove',
  65946: 'carriageJam',
  66012: 'carriageJamPrinthead',
  66162: 'carriageJamTube',
  66268: 'cartridgeCounterfeit',
  66267: 'cartridgeCounterfeit',
  65557: 'cartridgeLow',
  66242: 'cartridgeLowHP+',
  66017: 'cartridgeLowTanks',
  65546: 'cartridgeVeryLow',
  65776: 'cartridgeVeryLow',
  66241: 'cartridgeVeryLowHP+',
  66018: 'cartridgeVeryLowTanks',
  66332: 'cartridgeVeryLowTanks',
  65587: 'outputBinClosedOpenTray',
  66272: 'sharedAddressError',
  66209: 'sharedAddressErrorPrintheadFailure',
  66235: 'subscribedPagesOut',
  66174: 'subscribedPagesOutNotConnected',
  66336: 'subscribedPagesOutNotConnected',
  65779: 'subscribedPagesOutReconnect',
  65741: 'subscribedPagesOutReconnectOrReplace',
  65742: 'subscribedPagesOutUpdatePayment',
  65768: 'subscriptionCancellationSuccessful',
  65850: 'subscriptionCancellationSuccessfulXMO',
  65598: 'supplyForwardIncompatible',
  66270: 'supplyForwardIncompatible',
  65689: 'supplyForwardIncompatibleII',
  66228: 'supplyForwardIncompatiblePrintheads',
  65846: 'trialConsumableCountExceeded',
  65849: 'consumableSubscriptionOptionNotSet',
  66168: 'printerNeedsWSRegistration',
  66169: 'printerNeedsWSRegistration',
  65799: 'serviceStationStall',
  65592: 'cartridgeCounterfeitQuestion',
  65685: 'cartridgeCounterfeitQuestion',
  65838: 'cartridgeCounterfeitQuestion',
  65617: 'cartridgeCounterfeitQuestion',
  65686: 'cartridgeCounterfeitQuestion',
  65594: 'cartridgeCounterfeitQuestion',
  65687: 'cartridgeCounterfeitQuestion',
  66098: 'cartridgeCounterfeitQuestion',
  66099: 'cartridgeCounterfeitQuestion',
  66266: 'cartridgeCounterfeitQuestion',
  66269: 'cartridgeCounterfeitQuestion',
  65765: 'subscriptionConsumableNeedsEnrollment'
}

export const _IGNORABLE_CARTRIDGE_ALERTS = {
  65586: true, // calibrating
  65571: true, // calibrating
  65797: true, // trayOpen
  65702: true, // mediaTooLongToAutoDuplex
  65788: true, // mediaTooLongToAutoDuplex
  65572: true, // mediaTooShortToAutoDuplex
  65570: true, // paperSizeMismatch
  65701: true, // paperSizeMismatch
  65936: true, // paperSizeMismatch
  65573: true, // paperSizeMismatchWarning
  65809: true, // paperSizeMismatchWarning
  65810: true, // paperSizeMismatchWarning
  65716: true, // paperSizeMismatchWarning
  65717: true, // paperSizeMismatchWarning
  65798: true, // trayClosed
  66090: true, // trayEmptyLoadPaper
  65803: true, // trayEmptyOrOpenLoadPaper
  65922: true, // improperOrientationInTray
  ...Object.keys(_KNOW_PHOTO_TRAY_ALERTS).reduce((acc, key) => {
    acc[key] = true
    return acc
  }, {})
}

// Ink cartridge color identifiers

export const CARTRIDGE_COLORS = {
  CDM: {
    BLACK: 'K',
    YELLOW: 'Y',
    CYAN: 'C',
    MAGENTA: 'M',
    CYANMAGENTAYELLOW: 'CMY'
  },
  LEDM: {
    BLACK: 'Black',
    YELLOW: 'Yellow',
    CYAN: 'Cyan',
    MAGENTA: 'Magenta',
    CYANMAGENTAYELLOW: 'CyanMagentaYellow'
  }
}

// Monitoring
export const SPAN_WORKFLOW_NAME = 'workflow.name'
export const SPAN_MODEL_NAME = 'model.name'
export const SPAN_FLOW_TYPE = 'flow.type'
export const SPAN_FLOW_RESOURCE = 'flow.resource'
export const SPAN_FLOW_PAYLOAD = 'flow.payload'
export const SPAN_MODEL_NUMBER = 'model.number'
export const SPAN_FLOW_INTERFACE = 'flow.interface'
export const CDM_FLOW_INTERFACE_TYPE = 'CDM'
export const LEDM_FLOW_INTERFACE_TYPE = 'LEDM'

// Launch Darkly
export const FEATURE_FLAG_CLIENT = 'ld-onboarding-migration-client'
export const FEATURE_FLAG_KEYS = {
  enablePageDidNotPrint: 'omig-release-arespagedidnotprint'
}

// Carousel Arrow Direction
export const ARROW_LEFT = 'left'
export const ARROW_RIGHT = 'right'

// HP branding
export const SETUP_APP_BRANDING = {
  HP_SMART: 'HP Smart',
  HPX: 'HP'
}
